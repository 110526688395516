
  .icon {
    margin-right: 5px; /* Adjust icon spacing */
  }
  /* .framecard{
    max-width: 550px !important;
height: 425px !important;
  } */
  .prjcon{
    border-radius: 10px !important;
    box-shadow:none !important;
    border:none !important;
    border-image: initial;
    border-radius: 11px;
  }
  .imgcard {
    border: 1px solid #000000;
    width: 300px; 
    height: 300px; 
    background-color: #ffffff; 
    /* border-radius: 8px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    overflow: hidden; 
    margin: 20px; 
  }
  .backicon{
    text-align: center;
    position: absolute;
    padding-top: 8px;
    font-size: 35px;

  }
  .colprjname{
    display: flex;
    align-items: center;
    padding: 0% 3% !important;
  }
  label{
    font-weight: 500;
    font-size: 16px !important;
    color: #000000;
}
.boxcardcol{
    display: flex;
    align-items: center;
    justify-content: center;
}
.labelhead{
    font-weight: 500;
    font-size: 15px;
    color: #000000;
}
.downloadicon{
  position: absolute;
  top: 4%;
  right: 8%;
  font-size: 35px;
}
.printicon{
  position: absolute;
  top: 4%;
  right: 3%; 
  font-size: 31px;
}

@media(max-width:767px) {
.downloadicon {
  position: absolute;
  top: 4%;
  right: 10%;
  font-size: 20px;
}
.backicon {
  text-align: center;
  position: absolute;
  padding-top: 5px;
  font-size: 20px;
}
.printicon{
  position: absolute;
  top: 4%;
  right: 0%;
  font-size: 20px;

}
.heading{
  font-size: 20px;
  padding-top: 5px;
}
.boxcardcol {
  display: flex;
  align-items: end;
  justify-content: flex-start;
}
label {
  margin-top: 0px !important;
  padding-top: 0px !important;
  font-weight: 500;
  font-size: 13px !important;
  color: #000000;
  margin-bottom: 10px;
}
}



@media only screen and (min-width: 390px) and (max-width: 500px) {
  .downloadicon {
    position: absolute;
    top: 3% !important;
    right: 9% !important;
    font-size: 20px;
}
.printicon {
  position: absolute;
  top: 3%;
  right: 0%;
  font-size: 20px;
}

}
@media only screen and (min-width: 768px) and (max-width: 1124px) {
.downloadicon {
  position: absolute;
  top: 3%;
  right: 11%;
  font-size: 35px;
}
.printicon {
  position: absolute;
  top: 3%;
  right: 3%;
  font-size: 35px;
}
.table-responsive-md {
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch;
}
}
@media only screen and (min-width: 900px) and (max-width: 1024px) {

.downloadicon {
  position: absolute;
  top: 2% !important;
  right: 11%;
  font-size: 35px;
}
.printicon {
  position: absolute;
  top: 2% !important;
  right: 3%;
  font-size: 35px;
}
}

.actionbutton {
  display: inline-block !important;
  padding: 0px;
  font-size: 16px !important;
  text-align: center !important;
  text-decoration: none !important;
  border: 1px solid #000000 !important;
  color: #fff !important;
  background: linear-gradient(146deg, #281734, #2d1834, #464a77, #868bbd) !important;
  border-radius: 5px !important;
  transition: background-color 0.3s, color 0.3s !important;
}
.tablepara{
  padding: 10px;
  border: 1.8px solid #f2f2f2;
  margin-bottom: 0px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;

}
.text-danger {
  --bs-text-opacity: 1;
  font-size: 16px;
  /* text-transform: capitalize; */
  color: red !important;
}

@media print {
  body * {
    visibility: hidden;
  }
  #pdf-content, #pdf-content * {
    visibility: visible;
  }
}

.svgletter{
  text-align: center;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}


.custom-underline {
  display: inline;
  position: relative;
}

.custom-underline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px; /* Adjust this value to control the distance of the underline from the text */
  width: 100%;
  border-bottom: 2px solid #000; /* Change color as needed */
}

.svgtext{
  font-size: 20px;
  font-weight: bolder;
}

@media print {
  body * {
    visibility: visible;
  }
}


.print-container {
  display: block;
}

.print-row {
  display: flex;
  flex-wrap: wrap;
}

.print-row > .col {
  flex: 1;
  max-width: 33.333%;
}



@media print {
  .single-column {
    column-count: 1;
  }
  .page {
    break-inside: avoid;
  }


  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 20px;
    padding-top: 5%;
    padding-bottom: 6%;
    /* margin-top: 5%; */
    /* text-align: center; */

  }
  
  .paddingpdfh3{
    /* padding-top:5px; */
  padding-bottom: 7px; 
  }
  .emptycat {
    height: 10% !important;
    padding-top: 5%;
    padding-bottom: 6%;
  }
  .allsvgdiv{
    /* width: 500px !important; */
    /* height: 600px !important; */
  }
}

@media print {
  .print-content {
    display: block !important;
  }
}
@media screen {
  .print-content {
    display: none;
  }
}
.remarkcol{
  display: flex;
  align-items: center;
  justify-content: end;
}

@media only screen and (min-width: 320px) and (max-width: 700px) {
  .mblviewcutlist{
    padding-left: 5%;
    padding-right: 5%;
  }
.mblrow{
  margin-bottom:10px !important;
}
  .colorrow{
    margin-top: -10px !important;

  }
  .mblviewgap{
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
.mblcheck{
  position: absolute;
}
.label {
  padding-bottom: 10px;
  font-size: 13px !important;
  font-weight: 600;
}
.nodoorcheck{
  display: flex;
  justify-content: space-around;
  margin-top: 12% !important;
}

.editbutton{
  background: linear-gradient(179deg, #091b6a, #4ca9df) !important;
    width: 100% !important;
  border: none;
  display: flex;
  justify-content: center;

}
.edittd{
  text-align: center;

}
.dublicatebutton{
  background: linear-gradient(179deg, #a82654, #fd8c69) !important;
  width: 100% !important;
  border: none;
  display: flex;
  justify-content: center;
}
.deletebtn{
  background: linear-gradient(179deg, #e21c34, #500b28) !important;
  width: 100% !important;
  border: none;
  display: flex;
  justify-content: center;
}
.remarkcol {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3%;
}
.reportbutton{
  width: 10%;
    position: absolute;
    background: #000000 !important;
    border: none !important;
    width: 28% !important;
    right: 1% !important;
    top: 13% !important;
    border: none;
    display: flex;
}
}
.all2dimage{
  display: flex;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
}
.cutlistcontainer{
  border-radius: 10px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
    border: 1px solid #e6e6e6;
    border-image: initial;
    border-radius: 11px;
    padding-left: 2%;
    padding-right: 2%;
}
.cutlistmain{
  padding-left: 8%;
  padding-right: 8%;

}
.pdf-page {
  display: none;
}

.colorinput{
  border: 2px solid red !important;
}
.tabletableborder{
  border: 2px solid #1bac21;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.colorexcel{
  background-color:rgb(132 206 132);
}

.editbutton{
  background: linear-gradient(179deg, #091b6a, #4ca9df) !important;
    width: 80% ;
  border: none;
  display: flex;
  justify-content: center;

}
.reportbutton{
  width: 10% ;
  position: absolute;
  background: #000000 !important;
  border: none !important;
  width: 10%;
  right: 15%;
  top: 4%;
border: none;
display: flex;
justify-content: center;

}
.edittd{
  text-align: center;

}
.dublicatebutton{
  background: linear-gradient(179deg, #a82654, #fd8c69) !important;
  width: 49% ;
  border: none;
  display: flex;
  justify-content: center;
}
.deletebtn{
  background: linear-gradient(179deg, #e21c34, #500b28) !important;
  width: 56% ;
  border: none;
  display: flex;
  justify-content: center;
}
.whitetxt{
  color: #ffffff;
}
.nodoorcheck{
  display: flex;
  justify-content: space-around;
  margin-top: 30px !important ;
}
.greytxt{
  color: #e5e5e5;
}

.wardropebtn{
  display: inline-block !important;
    font-size: 14px !important;
    text-align: center !important;
    text-decoration: none !important;
    border: 1px solid #000000 !important;
    color: #fff !important;
    background: linear-gradient(146deg, #281734, #2d1834, #464a77, #868bbd) !important;
    border-radius: 5px !important;
    transition: background-color 0.3s, color 0.3s !important;
    height: 85%;
    margin-left: 5%;
}
.hidden{
  display: none !important;
}
.showing{
  /* display: block !important; */
}